import React, { useCallback, useState } from "react"
import styled from "styled-components"

import { useIsMobile } from "../../../hooks"
import {
  useApproveInvoice,
  useGetAssociatePayrollBreakdown,
  useGetYocoAddress,
  useUser,
} from "../../auth/hooks"
import { Chip, Button, Modal } from "../../components"
import { formatDateToString } from "../../utils/date"

const Container = styled.div<{
  compactTable: boolean
}>`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;

  .title {
    font-size: 20px;
  }

  .chip-container {
    display: flex;
  }

  p {
    font-size: 12px;
    margin: 0;
  }

  .header-info {
    p {
      line-height: 18px;
    }
  }

  .associate-total {
    align-self: center;
    display: flex;
    justify-content: flex-end;
  }
`

const InvoiceTable = styled.div<{
  isPrincipalUser: boolean
}>`
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, 1fr)) max-content;
  grid-column-gap: 5px;

  p {
    padding: 5px;
  }

  .table-heading {
    line-height: 15px;
    font-weight: 500;
  }

  .heading-placeholder {
    display: none;
  }

  .inline-heading {
    grid-column: -6 / -1;
    border-bottom: 1px solid ${({ theme }) => theme.palette.mineShaft};
    margin-bottom: 5px;
  }

  .adhoc {
    grid-column: 2;
  }

  hr {
    &.primary {
      grid-column: -6 / -1;
      border-bottom: 1px solid ${({ theme }) => theme.palette.mineShaft};
      margin: 5px 0px;
    }

    &.secondary {
      grid-column: -6 / -1;
      border-bottom: 1px solid ${({ theme }) => theme.palette.porcelain};
      margin: 5px 0px;
    }

    &.secondary:last-of-type {
      display: none;
    }

    &.spaced {
      margin: 5px 0px;
    }
  }

  .total-column {
    text-align: right;
  }

  .associate-performance-heading {
    line-height: 15px;
    font-weight: 500;
    grid-column: 1 / 3;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: ${({ isPrincipalUser: principalUser }) =>
      principalUser
        ? "minmax(min-content, max-content) repeat(4, max-content)"
        : "max-content repeat(2, 1fr) max-content"};
    grid-column-gap: 30px;

    .heading-placeholder {
      display: inline-block;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .inline-heading {
      grid-column: 1;
      border-bottom: none;
      margin-bottom: 0px;
    }
  }
`

const TotalHeader = styled.h5`
  grid-column: -4 / -3;
  font-size: 13px;
  margin: 10px 0px;

  &.invoice {
    font-weight: 700;
    margin-top: 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-column: -4 / -2;
  }
`

const TotalValue = styled.h6`
  grid-column: -3 / -1;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  margin: 10px 0px;

  &.invoice {
    font-size: 26px;
    margin-top: 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-column: -2 / -1;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  grid-column: 1 / 3;
  gap: 10px;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: 20px;
  }
`

interface Props {
  open: boolean
  onClose: () => void
  invoice: Reseller.Invoice
  onDownload: () => void
  principalCutLineItemLookup?: string
}

const InvoiceModal: React.FC<Props> = ({
  open,
  onClose,
  invoice,
  onDownload,
  principalCutLineItemLookup,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const { user } = useUser()
  const isMobile = useIsMobile()

  const isPrincipalUser = user?.profile?.profileType === "PRINCIPAL"

  const invoiceData = invoice

  const [data] = useGetYocoAddress()
  const yocoAddress = data?.yocoAddress
  const billingAddress = user?.address?.find(
    (address) => address.type === "INVOICING"
  )

  const vatRegistered = user?.profile?.isVatRegistered
  const approveInvoice = useApproveInvoice()

  const { associatePayrollItems } = useGetAssociatePayrollBreakdown()
  const associatePayrollNodes = associatePayrollItems?.edges.map(
    (item) => item.node
  )

  const renderResellerInvoiceItems = useCallback(
    () =>
      invoiceData.lineItems?.map((item, index) => {
        return (
          <>
            <p>
              {item.adHocCategory
                ? `${item.adHocCategory + ": " + item.description}`
                : item.description}
            </p>
            <p>
              {item.quantity
                ? item.quantity
                : item.percentage
                ? item.percentage + "%"
                : "-"}{" "}
            </p>
            <p>{item.itemAmount ? `R${item.itemAmount}` : "-"}</p>
            {!isPrincipalUser && <p />}
            <p className="total-column">
              R
              {vatRegistered
                ? item.totalAmountExclVat.toFixed(2)
                : item.totalAmount.toFixed(2)}
            </p>
            {index !== invoiceData.lineItems?.length - 1 ? (
              <hr className="secondary spaced" />
            ) : null}
          </>
        )
      }),
    [invoiceData]
  )

  const renderAssociatePayrollBreakdown = useCallback(
    () =>
      associatePayrollNodes?.map((node, nodeIndex) => {
        return (
          <>
            {nodeIndex == 0 ? (
              <>
                <p className="table-heading inline-heading">
                  {node.lineItems[0].category}
                </p>
                {!isMobile ? <hr className="secondary" /> : null}
              </>
            ) : null}

            {node.lineItems.map((lineItem, lineItemIndex) => {
              return (
                <>
                  {lineItemIndex != 0 ? (
                    <>{!isMobile && <p></p>}</>
                  ) : (
                    <p className="table-heading inline-heading">
                      {node.associateName}
                    </p>
                  )}
                  {lineItem.adHocCategory ? (
                    <p className="adhoc">
                      {lineItem.adHocCategory + ": " + lineItem.description}
                    </p>
                  ) : (
                    <p>{lineItem.description}</p>
                  )}
                  <p>{lineItem.quantity ?? "-"}</p>
                  <p>R{lineItem.itemAmount}</p>
                  <p className="total-column">
                    R
                    {vatRegistered
                      ? lineItem.totalAmountExclVat.toFixed(2)
                      : lineItem.totalAmount.toFixed(2)}
                  </p>
                  <hr className="secondary" />
                </>
              )
            })}

            <TotalHeader>Total</TotalHeader>
            <p className="associate-total">R{node.total}</p>

            {nodeIndex === associatePayrollItems?.edges.length - 1 ||
            isMobile ? (
              <hr className="primary" />
            ) : (
              <hr className="secondary" />
            )}
          </>
        )
      }),
    [
      associatePayrollItems?.edges.length,
      associatePayrollNodes,
      isMobile,
      vatRegistered,
    ]
  )

  const renderPrincipalInvoiceItems = useCallback(() => {
    const tempCategorisedLineItems = invoiceData.lineItems.reduce(
      (group: { [key: string]: Reseller.InvoiceLineItem[] }, lineItem) => {
        group[
          lineItem.adHocCategory ? lineItem.adHocCategory : lineItem.category
        ] = group[lineItem.category] ?? []
        group[
          lineItem.adHocCategory ? lineItem.adHocCategory : lineItem.category
        ].push(lineItem)

        return group
      },
      {}
    )

    const categorisedLineItemHeadings = Object.keys(tempCategorisedLineItems)
    const categorisedLineItems = Object.values(tempCategorisedLineItems)

    let principalCut = 0

    if (principalCutLineItemLookup) {
      if (
        categorisedLineItemHeadings.indexOf(principalCutLineItemLookup) > -1
      ) {
        principalCut = categorisedLineItems[
          categorisedLineItemHeadings.indexOf(principalCutLineItemLookup)
        ].reduce(
          (result, lineItem) =>
            result +
            (vatRegistered
              ? lineItem.totalAmountExclVat
              : lineItem.totalAmount),
          0
        )
      }
    }

    return (
      <>
        {categorisedLineItems.map((lineItemCategory, index) => {
          return (
            <>
              <p
                style={{
                  gridRow: `span ${
                    lineItemCategory.length + (lineItemCategory.length - 1)
                  }`,
                }}
                className="table-heading inline-heading"
              >
                {!categorisedLineItemHeadings[index].includes("Total Associate")
                  ? categorisedLineItemHeadings[index]
                  : null}
              </p>
              {lineItemCategory.map((lineItem, lineItemIndex) => (
                <>
                  {!lineItem.category.includes("Total Associate") ? (
                    <>
                      {lineItemIndex != 0 && <>{!isMobile && <p></p>}</>}
                      <p>{lineItem.description}</p>
                      <p>
                        {lineItem.quantity
                          ? lineItem.quantity
                          : lineItem.percentage + "%"}
                      </p>
                      <p>
                        {lineItem.itemAmount ? `R${lineItem.itemAmount}` : "-"}
                      </p>
                      <p className="total-column">
                        R
                        {vatRegistered
                          ? lineItem.totalAmountExclVat.toFixed(2)
                          : lineItem.totalAmount.toFixed(2)}
                      </p>
                      <hr className="secondary" />
                    </>
                  ) : null}
                </>
              ))}
            </>
          )
        })}
        {principalCut > 0 && (
          <>
            <TotalHeader>Your Total</TotalHeader>
            <TotalValue>R{principalCut.toFixed(2)}</TotalValue>
          </>
        )}
        <hr className="primary" />
      </>
    )
  }, [invoiceData.lineItems, principalCutLineItemLookup, vatRegistered])

  const onApproveInvoice = useCallback(
    async (invoiceId: string) => {
      try {
        setLoading(true)
        await approveInvoice(invoiceId)
        onClose()
      } catch (err) {
        console.warn(err)
      } finally {
        setLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [approveInvoice]
  )

  return (
    <Modal open={open} onClose={onClose}>
      <Container compactTable={isPrincipalUser}>
        <h3 className="title">{`Invoice ${formatDateToString(
          invoiceData.createdAt
        )}`}</h3>
        <div className="chip-container">
          <Chip
            size="large"
            text={invoiceData.status}
            backgroundColor="#dff5fb"
            textColor="#00a9e0"
            borderColor="transparent"
          />
        </div>
        <span className="header-info">
          <p>Issued: {invoiceData.createdAt}</p>
          <p>Ref: {invoiceData.referenceNumber}</p>
        </span>
        <p>
          {user?.profile?.vatNumber && `VAT Number: ${user.profile.vatNumber}`}
        </p>
        {yocoAddress && (
          <div className="header-info">
            <p>
              <strong>To: </strong>
              {yocoAddress.name}
            </p>
            <p>{yocoAddress.addressLine1}</p>
            <p>{yocoAddress.addressLine2}</p>
            <p>{yocoAddress.city}</p>
            <p>{yocoAddress.postalCode}</p>
          </div>
        )}

        {billingAddress && (
          <div className="header-info">
            <p>
              <strong>From: </strong>
              {billingAddress.firstName} {billingAddress.lastName}
            </p>
            <p>{billingAddress.addressLine1}</p>
            <p>{billingAddress.addressLine2}</p>
            <p>{billingAddress.city}</p>
            <p>{billingAddress.postalCode}</p>
          </div>
        )}
        <InvoiceTable isPrincipalUser={isPrincipalUser}>
          <p className="table-heading">Description</p>
          {isPrincipalUser ? (
            <span className="table-heading heading-placeholder" />
          ) : null}
          <p className="table-heading">Units</p>
          <p className="table-heading">Amount</p>
          {!isPrincipalUser && <p />}
          <p className="table-heading total-column">Total</p>
          <hr className="primary" />
          {isPrincipalUser ? renderAssociatePayrollBreakdown() : null}
          {isPrincipalUser
            ? renderPrincipalInvoiceItems()
            : renderResellerInvoiceItems()}
          {vatRegistered ? (
            <>
              <TotalHeader>Total Excl. VAT</TotalHeader>
              <TotalValue>
                R{invoiceData.totalAmountExclVat.toFixed(2)}
              </TotalValue>
              <TotalHeader>VAT Amount</TotalHeader>
              <TotalValue>R{invoiceData.totalVatAmount.toFixed(2)}</TotalValue>
            </>
          ) : null}
          <hr className="primary" />
          <TotalHeader className="invoice">Invoice Total</TotalHeader>
          <TotalValue className="invoice">
            R{invoiceData.totalAmount.toFixed(2)}
          </TotalValue>
        </InvoiceTable>
        <ButtonsContainer>
          <Button
            color="inherit"
            textColor="#222"
            textAlign="left"
            label="Download"
            disabled={!invoiceData.pdf}
            loading={loading}
            onClick={onDownload}
          />
          {invoiceData.status === "CREATED" ? (
            <Button
              color="#00a9e0"
              textColor="#fff"
              label="Approve for payment"
              iconPath="/images/icons/icon-circled-check.svg#base"
              onClick={() => onApproveInvoice(invoiceData.id)}
            />
          ) : null}
        </ButtonsContainer>
      </Container>
    </Modal>
  )
}

export default InvoiceModal
