import { NetworkStatus } from "@apollo/client"
import { Link } from "gatsby"
import React, { useState, useCallback } from "react"
import styled from "styled-components"

import { useNotify } from "../../alert/hooks"
import { useGetInvoicePdf, useGetInvoices, useUser } from "../../auth/hooks"
import { verifySession } from "../../auth/utils"
import {
  PageHeader,
  PageGuard,
  Filter,
  PageNotificationBanner,
  Chip,
  ActionDropdown,
} from "../../components"
import Seo from "../../components/seo"
import TableBase, { TableHeading } from "../../components/Table"
import InvoiceModal from "../../invoices/components/InvoiceModal"
import { formatMonthToString } from "../../utils/date"

const Table = styled(TableBase)`
  margin: 20px 0;

  .table-row {
    grid-template-areas:
      "date status"
      "price status"
      "download view";

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-template-areas: "date price status download view";
    }
  }
`

const ChipContainer = styled.div`
  display: flex;
  grid-area: status;
  justify-self: end;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-self: start;
  }
`

const Price = styled.p`
  grid-area: price;
  padding-left: 32px;
  margin: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 0px;
  }
`

const renderChip = (status: string, index: number) => {
  const borderColor =
    status === "CREATED"
      ? "rgba(0,169,224,0.3)"
      : status === "APPROVED"
      ? "#ffe9c7"
      : status === "PAID"
      ? "#e1eae8"
      : status === "DISPUTED"
      ? "#ffd4c6"
      : ""

  const textColor =
    status === "CREATED"
      ? "#00a9e0"
      : status === "APPROVED"
      ? "#ff9e00"
      : status === "PAID"
      ? "#222"
      : status === "DISPUTED"
      ? "#fa5d37"
      : ""

  const text =
    status === "CREATED"
      ? "Created"
      : status === "APPROVED"
      ? "Approved"
      : status === "PAID"
      ? "Paid"
      : status === "DISPUTED"
      ? "Disputed"
      : ""

  return (
    <ChipContainer key={`chip-container-${index}`}>
      <Chip
        text={text}
        backgroundColor="inherit"
        borderColor={borderColor}
        borderWidth="2px"
        textColor={textColor}
        size="medium"
        fontWeight="500"
      />
    </ChipContainer>
  )
}

const renderPrice = (price: number) => {
  return price.toLocaleString("en-GB", { style: "currency", currency: "ZAR" })
}

const Invoices = (): React.ReactElement => {
  const sessionToken = verifySession()
  const notify = useNotify()
  const { user } = useUser()

  const { data, loading, refetch, fetchMore } = useGetInvoices()
  const [getInvoicePdf, networkStatus, refetchInvoice] = useGetInvoicePdf()

  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false)
  const [invoiceCSVLoading, setInvoiceCSVLoading] = useState(false)
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0)
  const [invoicePdfFetched, setInvoicePdfFetched] = useState(false)
  const invoicePdfLoading = networkStatus !== NetworkStatus.ready

  const invoices = data?.edges.map((invoice) => {
    return invoice.node
  })
  const [selectedInvoiceIndex, setSelectedInvoiceIndex] = useState(0)

  const invoiceFilters: {
    title: string
    value?: Reseller.InvoiceStatusType
    quantity?: number
  }[] = [
    {
      title: "All Invoices",
      value: undefined,
      quantity: data?.count?.all,
    },
    {
      title: "Ready to send",
      value: "CREATED",
      quantity: data?.count?.created,
    },
    { title: "Processing", value: "APPROVED", quantity: data?.count?.approved },
    { title: "Paid", value: "PAID", quantity: data?.count?.paid },
  ]

  const handleFilterChange = (index: number) => {
    refetch({
      status: invoiceFilters[index].value,
    })
    setSelectedFilterIndex(index)
  }

  const handleFetchMoreInvoices = useCallback(() => {
    fetchMore({
      variables: {
        after: data?.pageInfo.endCursor,
      },
    })
  }, [fetchMore, data?.pageInfo.endCursor])

  const onDownloadCsv = useCallback(
    async (index: number) => {
      const active_invoice = invoices[index]
      setInvoiceCSVLoading(true)
      const url = `${process.env.GATSBY_YOCO_INVOICE_CSV_ENDPOINT}${active_invoice.id}`
      await fetch(url, {
        headers: {
          "content-Type": "application/json",
          authorization: sessionToken?.token ? `JWT ${sessionToken.token}` : "",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          setInvoiceCSVLoading(false)

          const _url = window.URL.createObjectURL(blob)
          window.open(_url, "_blank")?.focus()
        })
        .catch(() => {
          notify(
            "danger",
            "Could not download your invoice csv",
            "please try again later"
          )
        })
    },
    [invoices, sessionToken?.token, notify]
  )

  const onDownloadPdf = async (index: number, refetch = false) => {
    const active_invoice = invoices[index]
    if ((invoicePdfFetched || refetch) && refetchInvoice) {
      refetchInvoice({ id: active_invoice.id })
    } else {
      getInvoicePdf({ variables: { id: active_invoice.id } })
      setInvoicePdfFetched(true)
    }
  }

  const onViewInvoice = (index: number) => {
    setSelectedInvoiceIndex(index)
    setInvoiceModalOpen(true)
  }

  const tableHeadings: TableHeading[] = [
    {
      name: "Date Issued",
      accessor: "createdAt",
      columnAllowance: "1fr",
      renderData: (index: number, createdAt: string) => {
        const date = new Date(createdAt)
        const formattedDate = `${date.getDate()} ${formatMonthToString(
          date,
          true
        )} ${date.getFullYear()}`

        return (
          <div
            key={`date-issued-${index}`}
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              gridArea: "date",
            }}
          >
            <img src="/images/icons/icon-date.svg" alt="Date icon" />
            <p>{formattedDate}</p>
          </div>
        )
      },
    },
    {
      name: "Amount",
      accessor: "totalAmount",
      columnAllowance: "1fr",
      renderData: (index: number, totalAmount: number) => (
        <Price key={`price-${index}`} style={{ gridArea: "price" }}>
          {renderPrice(totalAmount)}
        </Price>
      ),
    },
    {
      name: "Status",
      accessor: "status",
      columnAllowance: "1fr",
      renderData: (index: number, status: string) => renderChip(status, index),
    },
    {
      name: "",
      accessor: "pdf",
      columnAllowance: "1fr",
      renderData: (index: number, pdf: string) => {
        if (pdf && pdf !== "") {
          if (user?.profile?.profileType === "PRINCIPAL") {
            return (
              <div key={`actions-${index}`}>
                <ActionDropdown
                  loading={invoiceCSVLoading || invoicePdfLoading}
                  actions={[
                    {
                      label: "View Invoice",
                      onClick: () => onViewInvoice(index),
                    },
                    {
                      label: "Download PDF",
                      onClick: () => onDownloadPdf(index),
                    },
                    {
                      label: "Download CSV",
                      onClick: () => onDownloadCsv(index),
                    },
                  ]}
                />
              </div>
            )
          } else {
            return (
              <div key={`actions-${index}`}>
                <ActionDropdown
                  loading={invoiceCSVLoading || invoicePdfLoading}
                  actions={[
                    {
                      label: "View Invoice",
                      onClick: () => onViewInvoice(index),
                    },
                    {
                      label: "Download PDF",
                      onClick: () => onDownloadPdf(index),
                    },
                  ]}
                />
              </div>
            )
          }
        } else {
          return <div key={`download-placeholder-${index}`} />
        }
      },
    },
  ]

  return (
    <PageGuard
      verifyUserProfile
      allowedProfileTypes={["RESELLER", "PRINCIPAL"]}
    >
      <Seo title="Invoices" />
      <PageHeader cypressTestId="invoices-page-header" title="Invoices" />
      <PageNotificationBanner>
        {user?.profile?.verificationStatus !== "SUCCESSFUL" ? (
          <p>
            Your identity has not been verified yet. Please{" "}
            <Link className="highlighted" to="/onfido/">
              verify your identity
            </Link>{" "}
            to list your invoices and receive your payouts.
          </p>
        ) : (
          <p>View and approve your invoices.</p>
        )}
      </PageNotificationBanner>
      {user?.profile?.verificationStatus === "SUCCESSFUL" ? (
        <>
          {" "}
          <Filter
            isPageFilter
            filterOptions={invoiceFilters}
            onClick={handleFilterChange}
            activeIndex={selectedFilterIndex}
          />
          <Table
            headings={tableHeadings}
            rowData={invoices}
            loading={loading}
            emptyTableText="You have no invoices to view"
            allRowsLoaded={!data?.pageInfo.hasNextPage}
            handleLoadMoreRows={handleFetchMoreInvoices}
          />
          {!loading && invoices?.length > 0 && invoiceModalOpen ? (
            <InvoiceModal
              open={invoiceModalOpen}
              onClose={() => setInvoiceModalOpen(false)}
              invoice={invoices[selectedInvoiceIndex]}
              principalCutLineItemLookup={data?.principalCutLineItemLookup}
              onDownload={() => onDownloadPdf(selectedInvoiceIndex)}
            />
          ) : null}
        </>
      ) : null}
    </PageGuard>
  )
}

export default Invoices
