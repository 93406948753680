export const humanizeDateTime = (startDate: Date, endDate: Date): string => {
  let period: number = Math.floor(
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  )
  let unit = period == 1 ? "day" : "days"

  if (period > 6) {
    period = Math.floor(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 7)
    )
    unit = period == 1 ? "week" : "weeks"
  }

  if (period > 365) {
    period = Math.floor(
      (endDate.getTime() - startDate.getTime()) /
        (1000 * 60 * 60 * 24 * 7 * 365)
    )
    unit = period == 1 ? "year" : "years"
  }

  return period + " " + unit
}

export const formatMonthToString = (
  date: Date | undefined,
  abbreviated?: boolean
): string => {
  if (date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]

    const stringMonth = abbreviated
      ? monthNames[date.getMonth()].substring(0, 3)
      : monthNames[date.getMonth()]
    return stringMonth
  } else {
    return "-"
  }
}

export const formatDateToString = (dateString: string | null): string => {
  if (dateString !== null) {
    const date = new Date(dateString)
    return (
      date.getDate() +
      " " +
      formatMonthToString(date, true) +
      " " +
      date.getFullYear()
    )
  } else {
    return "-"
  }
}

export const formatDateTimeToString = (
  dateTimeString: string | null
): string => {
  if (dateTimeString !== null) {
    const date = formatDateToString(dateTimeString)
    const timeIndex = dateTimeString.indexOf("T") + 1
    if (timeIndex !== -1) {
      const timeIndex = dateTimeString.indexOf("T") + 1
      const time = dateTimeString.substring(timeIndex).substring(0, 5)
      return `${date} at ${time}`
    } else {
      return date
    }
  } else {
    return "-"
  }
}

export const convertDateTimeZone = (dateTimeString: string | null): string => {
  if (dateTimeString !== null) {
    const tempDate = dateTimeString.slice(0, dateTimeString.indexOf("+"))
    return new Date(tempDate).toString()
  } else {
    return "invalid date"
  }
}

export const timeUntilDate = (dateTimeString: string | null): string => {
  const dateDiffInDays = (currDate: Date, targetDate: Date) => {
    const currDateUTC = Date.UTC(
      currDate.getFullYear(),
      currDate.getMonth(),
      currDate.getDate()
    )
    const targetDateUTC = Date.UTC(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      targetDate.getDate()
    )

    const dayDifference = Math.floor(
      (targetDateUTC - currDateUTC) / (1000 * 60 * 60 * 24)
    )
    let resultTimeDifference = ""
    if (dayDifference === 0) {
      const hourDifference = targetDate.getHours() - currDate.getHours()
      if (hourDifference === 0) {
        const minuteDifference = targetDate.getMinutes() - currDate.getMinutes()
        resultTimeDifference = "less than 1 hour"
        if (minuteDifference < 0) {
          resultTimeDifference = resultTimeDifference + " ago"
        }
      } else {
        resultTimeDifference = `${hourDifference} hour${
          hourDifference !== 1 && hourDifference !== -1 ? "s" : ""
        }`
      }
    } else {
      resultTimeDifference = `${dayDifference} day${
        dayDifference !== 1 && dayDifference !== -1 ? "s" : ""
      }`
    }
    if (resultTimeDifference[0] === "-") {
      resultTimeDifference = resultTimeDifference.substring(1) + " ago"
    }

    return resultTimeDifference
  }

  if (dateTimeString !== null) {
    const currentDate = new Date()
    const targetDate = new Date(dateTimeString)
    return dateDiffInDays(currentDate, targetDate)
  } else {
    return "invalid date"
  }
}
